.App {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: scroll;
  overflow-x: hidden;
}
/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
  width: 0px;
}

.backgroundImage {
  background-image: url("../public/main.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  min-height: 100vh;
}

@media (max-width: 568px) {
  .backgroundImage {
    background-attachment: scroll;
  }
}
